<template>
  <v-layout v-if="loading" class="d-flex align-center justify-center" style="padding-top: 15px">
    <v-progress-circular indeterminate="indeterminate" color="primary" size="48"></v-progress-circular>
  </v-layout>
  <div class="mb-2" v-else>
    <div v-if="!reportOpen" class="pa-4">
      <v-layout column class="my-4">
        <v-layout row>
          <v-flex shrink>
            <v-icon large @click="$router.push('/reports/list')">{{ lang === 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right'}}</v-icon>
          </v-flex>
          <v-flex grow>
            <v-layout justify-center align-center>
              <h1>{{ $t('Installation Monitoring Report') }}</h1>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-layout row class="my-2">
        <v-flex>
          <div class="tabs">
            <button @click="setView('installations')" :class="[tab === 'installations' ? 'button-active' : '', lang === 'en' ? 'mr-2' : 'ml-2']">
              {{ $t('Weekly Devices Installation') }}
            </button>
            <button @click="setView('cabinet')" :class="[tab === 'cabinet' ? 'button-active' : '', lang === 'en' ? 'mr-2' : 'ml-2']">
              {{ $t('Report by Cabinet') }}
            </button>
            <button @click="setView('commissioner')" :class="[tab === 'commissioner' ? 'button-active' : '', lang === 'en' ? 'mr-2' : 'ml-2']">
              {{ $t('Report by Installer') }}
            </button>
            <button @click="setView('dates')" :class="[tab === 'dates' ? 'button-active' : '', lang === 'en' ? 'mr-2' : 'ml-2']">
              {{ $t('Report by Dates') }}
            </button>
          </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xl12>
          <DailyCommissioned v-if="tab === 'installations'"/>
          <v-card class="blue-rounded-card pa-3" style="background-color: white !important" v-if="tab === 'cabinet' || tab === 'commissioner' || tab === 'dates'">
            <div v-if="tab === 'cabinet'">
              <v-layout row wrap justify-center class="cardHeaderMonitoring mt-2">
                {{ $t('Report by Cabinet') }}
              </v-layout>
              <v-layout row wrap v-if="cabinetsList.length > 0">
                <v-flex xl6>
                  <v-layout justify-center class="mt-2 mb-2 selectHeaderMonitoring">
                    {{ $t('Select Cabinets') }}
                  </v-layout>
                  <v-layout justify-center class="my-2">
                    <Multiselect
                      v-model="queryCabinets"
                      :selectLabel="$t('Press enter to select')"
                      :deselectLabel="$t('Press enter to remove')"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :options="cabinetsList"
                      :placeholder="this.$t('Select Cabinets')"
                      :searchable="true"
                      :hide-selected="true"
                      open-direction="bottom"
                    />
                  </v-layout>
                  <v-layout class="my-2" justify-center>
                    <button
                      class="selectionBtn ml-4 mr-4"
                      @click="queryCabinets = cabinetsList"
                    >
                      {{ $t('Select All') }}
                    </button>
                    <button class="selectionBtn" @click="queryCabinets = []">
                      {{ $t('Clear Selection') }}
                    </button>
                  </v-layout>
                  <v-layout justify-center class="my-2 selectErr" v-if="!queryCabinets || queryCabinets.length === 0">
                    {{ $t('Select cabinets to continue') }}
                  </v-layout>
                </v-flex>
                <v-flex xl6>
                  <v-layout justify-center class="mt-2 mb-0 selectHeaderMonitoring">
                    {{ $t('Select Dates') }}
                  </v-layout>
                  <v-layout class="mt-3">
                    <v-layout column justify-center align-center>
                      <h3>{{ $t('From') }}</h3>
                      <DatePicker
                        @dateChanged="setStartDate"
                        :date="start_date"
                      />
                    </v-layout>
                    <v-layout column justify-center align-center>
                      <h3>{{ $t('To') }}</h3>
                      <DatePicker @dateChanged="setEndDate" :date="end_date" />
                    </v-layout>
                  </v-layout>
                  <v-layout row justify-center align-center>
                    <v-btn color="grey" class="white--text" @click="setYesterday">{{ $t('Yesterday') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastWeek">{{ $t('Last Week') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastMonth">{{ $t('Last Month') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastYear">{{ $t('Last Year') }}</v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-center v-else>
                <div class="redText errorTextBold">
                  {{ $t('No cabinets available for this project') }}
                </div>
              </v-layout>
            </div>
            <div v-else-if="tab === 'commissioner'">
              <v-layout row wrap justify-center class="cardHeaderMonitoring mt-2">
                {{ $t('Report by Installer') }}
              </v-layout>
              <v-layout row wrap v-if="commissionersList.length > 0">
                <v-flex xl6>
                  <v-layout justify-center class="my-2 selectHeaderMonitoring">
                    {{ $t('Select Installer') }}
                  </v-layout>
                  <v-layout justify-center class="my-2">
                    <Multiselect
                      v-model="queryCommissioners"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :options="commissionersList"
                      :placeholder="this.$t('Select Installers')"
                      :searchable="true"
                      :hide-selected="true"
                      open-direction="bottom"
                      :selectLabel="$t('Press enter to select')"
                      :deselectLabel="$t('Press enter to remove')"
                    />
                  </v-layout>
                  <v-layout justify-center class="my-2">
                    <button
                      class="selectionBtn ml-4 mr-4"
                      @click="queryCommissioners = commissionersList"
                    >
                      {{ $t('Select All') }}
                    </button>
                    <button
                      class="selectionBtn"
                      @click="queryCommissioners = []"
                    >
                      {{ $t('Clear Selection') }}
                    </button>
                  </v-layout>
                  <v-layout justify-center class="my-2 selectErr" v-if="!queryCommissioners || queryCommissioners.length === 0">
                    {{ $t('Select installers to continue') }}
                  </v-layout>
                </v-flex>
                <v-flex xl6>
                  <v-layout justify-center class="mt-2 mb-0 selectHeaderMonitoring">
                    {{ $t('Select Dates') }}
                  </v-layout>
                  <v-layout class="mt-3">
                    <v-layout column justify-center align-center>
                      <h3>{{ $t('From') }}</h3>
                      <DatePicker
                        @dateChanged="setStartDate"
                        :date="start_date"
                      />
                    </v-layout>
                    <v-layout column justify-center align-center>
                      <h3>{{ $t('To') }}</h3>
                      <DatePicker @dateChanged="setEndDate" :date="end_date" />
                    </v-layout>
                  </v-layout>
                  <v-layout row justify-center align-center>
                    <v-btn color="grey" class="white--text" @click="setYesterday">{{ $t('Yesterday') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastWeek">{{ $t('Last Week') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastMonth">{{ $t('Last Month') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastYear">{{ $t('Last Year') }}</v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-center v-else>
                <div class="redText errorTextBold">
                  {{ $t('No installers available for this project') }}
                </div>
              </v-layout>
            </div>
            <div v-else-if="tab === 'dates'">
              <v-layout row wrap justify-center class="cardHeaderMonitoring mt-2">
                {{ $t('Report by Dates') }}
              </v-layout>
              <v-layout row wrap>
                <v-flex xl6>
                  <v-layout justify-center class="mt-2 mb-4 selectHeaderMonitoring">
                    {{ $t('Select Tables Sorting Method') }}
                  </v-layout>
                  <v-layout justify-center class="my-2">
                    <Multiselect
                      v-if="lang === 'en'"
                      v-model="sortBy"
                      :close-on-select="true"
                      :options="sortList"
                      :placeholder="this.$t('Select Sorting Method')"
                      open-direction="bottom"
                      :selectLabel="$t('Press enter to select')"
                      :deselectLabel="$t('Press enter to remove')"
                    />
                    <Multiselect
                      v-else
                      v-model="sortBy"
                      :close-on-select="true"
                      :options="sortListHe"
                      :placeholder="this.$t('Select Sorting Method')"
                      open-direction="bottom"
                      :selectLabel="$t('Press enter to select')"
                      :deselectLabel="$t('Press enter to remove')"
                    />
                  </v-layout>
                  <v-layout justify-center class="my-2 selectErr" v-if="sortBy === null">
                    {{ $t('Select table sorting method to continue') }}
                  </v-layout>
                  <v-layout justify-center class="my-2 selectErr" v-if="(sortBy === 'cabinet' || sortBy === 'מרכזיה') && cabinetsList.length === 0">
                    {{ $t('No Cabinets in this project') }}
                  </v-layout>
                </v-flex>
                <v-flex xl6>
                  <v-layout justify-center class="mt-2 mb-0 selectHeaderMonitoring">
                    {{ $t('Select Dates') }}
                  </v-layout>
                  <v-layout class="mt-3">
                    <v-layout column justify-center align-center>
                      <h3>{{ $t('From') }}</h3>
                      <DatePicker
                        @dateChanged="setStartDate"
                        :date="start_date"
                      />
                    </v-layout>
                    <v-layout column justify-center align-center>
                      <h3>{{ $t('To') }}</h3>
                      <DatePicker @dateChanged="setEndDate" :date="end_date" />
                    </v-layout>
                  </v-layout>
                  <v-layout row justify-center align-center>
                    <v-btn color="grey" class="white--text" @click="setYesterday">{{ $t('Yesterday') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastWeek">{{ $t('Last Week') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastMonth">{{ $t('Last Month') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastYear">{{ $t('Last Year') }}</v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </div>
            <v-layout row class="mt-3 mx-4">
              <v-btn
                round
                dark
                :class="lang === 'en' ? 'ml-auto' : 'mr-auto'"
                color="#812E81"
                @click="openReport"
                :disabled="
                  period === null && tab !== 'dates'
                    ? true
                    : tab === 'dates' && sortBy === null
                    ? true
                    : tab === 'cabinet' && queryCabinets.length === 0
                    ? true
                    : tab === 'commissioner' && queryCommissioners.length === 0
                    ? true
                    : false
                "
                >{{ $t('Generate Monitoring Report') }}</v-btn
              >
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <div v-else class="printElement pa-4">
      <v-layout column class="my-4">
        <v-layout row>
          <v-flex shrink>
            <v-icon large @click="closeReport">{{ lang === 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
          </v-flex>
          <v-flex grow>
            <v-layout justify-center align-center>
              <h1>{{ $t('Installation Monitoring Report') }}</h1>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 class="mt-3 btnsHide">
          <ExportData
            v-if="exportAllData.length > 0"
            :data="exportAllData"
            :text="$t('Export All devices')"
            color="indigo accent-4"
            :sourceTitle="`Installation Monitoring Report- All devices - Dates:${timeStart}-${timeEnd}`"
            :removeDate="true"
            :noParsing="true"
          />
          <ExportData
            v-if="exportData.length > 0"
            :data="exportData"
            :text="$t('Export Installation Fail')"
            color="indigo accent-4"
            :sourceTitle="`Installation Monitoring Report- Installation Fail - Dates:${timeStart}-${timeEnd}`"
            :removeDate="true"
            :noParsing="true"
            class="mx-2"
          />
          <v-btn 
            style="color: #304ffe; border: 1px solid #304ffe; background-color: #f2f4f8;"
            :class="exportData.length > 0 ? 'mx-0' : 'mx-2'"
            @click="print"
            >{{ $t('Print') }}</v-btn
          >
        </v-flex>
      </v-layout>
      <VirtualList
        class="monitoring-report-data"
        style="height: 100%; overflow-y: auto;"
        data-key="cabinet_id"
        :data-sources="viewedTableData"
        :data-component="monitoring_report_item"
        :extra-props="{
          filterDevicesList,
          mapOpen,
          viewedTableData,
          tab,
          sortBy,
          timeStart,
          timeEnd,
          realCabinetsList,
          openCabinetPage,
          headers,
          commissionersList,
          cabinetsList,
          changeIndex,
          cabinetsFullData,
          resetChange,
          selectMapCommissioner,
          selectMapCabinet,
          selectMapDevices,
          selectCol
        }"
      />
      <v-layout row wrap justify-center v-if="viewedTableData.length === 0" class="noDataAvail">
        {{ $t('No data available for this selection') }}
      </v-layout>
    </div>
    <v-layout v-if="cabinetPage">
      <v-dialog v-model="cabinetPage" persistent>
        <FullViewTab
          style="background-color: white !important"
          :device="cabinetData"
          :cabinetDevices="cabinetDevices"
          :closeBtn="true"
          @close="cabinetPage = false"
        />
      </v-dialog>
    </v-layout>
  </div>
</template>
<script>
import DatePicker from '@/pages/reports/components/DatePicker.vue';
import FullViewTab from '@/pages/mapView/components/MapTabs/information/hubTabs/FullViewTab.vue';
import moment from 'moment-timezone';
import ExportData from '@/components/ExportData.vue';
import MapMixin from '../pages/mapView/components/MapTabs/devices/TabsInfoMixin.ts';
import Multiselect from 'vue-multiselect';
import Vue from 'vue';
import Utils from '@/modules/Utils';
import DailyCommissioned from '@/pages/reports/DailyCommissioned.vue';
import VirtualList from 'vue-virtual-scroll-list';
import MonitoringReportItem from '@/pages/reports/components/MonitoringReportItem.vue';

export default {
  name: 'MonitoringReport',
  components: {
    DatePicker,
    ExportData,
    Multiselect,
    FullViewTab,
    DailyCommissioned,
    VirtualList,
    MonitoringReportItem
  },
  mixins: [MapMixin],
  data() {
    return {
      lang: this.$store.state.Global.lang,
      tab: 'cabinet',
      commissionersList: [],
      cabinetsList: [],
      queryCabinets: [],
      queryCommissioners: [],
      start_date: null,
      end_date: null,
      set_default: true,
      projectTimezone: null,
      reportOpen: false,
      projectStartTime: null,
      projectEndTime: null,
      deviceList: null,
      tableData: null,
      viewedTableData: null,
      headers: null,
      timeStart: null,
      timeEnd: null,
      datesChange: false,
      commissionersChange: false,
      cabinetsChange: false,
      exportData: null,
      exportAllData: null,
      filterDevicesList: null,
      period: this.$t('week'),
      periodList: ['week', 'month', 'year', 'all'],
      periodListHe: ['שבוע', 'חודש', 'שנה', 'הכל'],
      sortBy: this.$t('cabinet'),
      sortList: ['cabinet', 'installer'],
      sortListHe: ['מרכזיה', 'מתקין'],
      mapOpen: null,
      allCabinets: null,
      changeIndex: null,
      realCabinetsList: null,
      allDevices: null,
      cabinetPage: false,
      cabinetDevices: null,
      cabinetData: null,
      loading: true,
      monitoring_report_item: MonitoringReportItem
    };
  },
  mounted() {
    this.reportOpen = false;
    this.lang = this.$store.state.Global.lang;
    this.projectTimezone = this.$store.state.Global.timezone;
    this.headers = [
      { text: 'Date', value: 'date', align: 'center', sortable: false },
      {
        text: 'Installer',
        value: 'commissioner',
        align: 'center',
        sortable: false
      },
      { text: 'Cabinet', value: 'cabinet', align: 'center', sortable: false },
      {
        text: 'Installed Devices',
        value: 'installed',
        align: 'center',
        sortable: false
      },
      {
        text: 'Installation Success',
        value: 'connected',
        align: 'center',
        sortable: false
      },
      {
        text: 'Installation Fail',
        value: 'disconnected',
        align: 'center',
        sortable: false
      }
    ];
    this.$store.commit('Global/setPageTitle', 'Installation Monitoring Report');
    this.period = this.$t('all');
    this.sortBy = this.$t('cabinet');
    this.generatePageData();
  },
  methods: {
    generatePageData(){
      if (this.devices.length !== this.devicesCount) return;
      const allCabinets = this.devices.filter((device) => Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device']));
      const allDevices = this.devices.filter((device) => Utils.hasTondoClass(device.class_name));
      const cabinets = [];
      const realCabinets = [];
      for (const dev of allDevices) {
        if (dev['meta.device'].hasOwnProperty('cabinet_id') && dev['meta.device'].cabinet_id.length > 0) {
          cabinets.push(dev['meta.device'].cabinet_id);
        }
      }
      for (const cabinet of allCabinets) {
        if (cabinet['meta.device'].hasOwnProperty('cabinet_id') && cabinet['meta.device'].cabinet_id.length > 0) {
          realCabinets.push(cabinet['meta.device'].cabinet_id);
        }
      }
      this.allCabinets = allCabinets;
      this.cabinetsList = [...new Set(cabinets)];
      this.queryCabinets = this.cabinetsList;
      this.realCabinetsList = [...new Set(realCabinets)];
      this.cabinetsFullData = JSON.parse(JSON.stringify(allCabinets));
      this.period = this.$t('all');
      this.tab = 'installations';
      this.setDefaultDates();
      this.getDevices();
      this.loading = false;
    },
    openCabinetPage(cabinetId) {
      const cabinet = this.cabinetsFullData.find((cabinet) => cabinet['meta.device'].cabinet_id === cabinetId);
      this.cabinetData = cabinet;
      const devices = [];
      for (const device of this.allDevices) {
        if (device['meta.device'].hasOwnProperty('cabinet_id') && device['meta.device']['cabinet_id'] === cabinetId) {
          devices.push(device);
        }
      }
      this.cabinetDevices = devices;
      this.cabinetPage = true;
    },
    open(index) {
      this.selectCol(index, 'Installed Devices', !this.mapOpen[index]);
      Vue.set(this.mapOpen, index, !this.mapOpen[index]);
      // this.mapOpen[index] = !this.mapOpen[index];
    },
    changeDates() {
      this.datesChange = !this.datesChange;
    },
    changeCommissioner() {
      this.commissionersChange = !this.commissionersChange;
    },
    changeCabinets() {
      this.cabinetsChange = !this.cabinetsChange;
    },
    getString(arr) {
      for (let i = 0; i < arr.length; i++) {
        arr[i] = this.$t(arr[i]);
        arr[i] = this.$t(arr[i]);
      }
      return arr;
    },
    setView(view) {
      this.tab = view;
    },
    clearAll() {
      this.queryCabinets = [];
    },
    openReport() {
      const allCabinets = this.devices.filter((device) => Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device']));
      this.allCabinets = allCabinets;
      const startDate = new Date(
        this.start_date.substring(0, 4),
        parseInt(this.start_date.substring(5, 7), 10) - 1,
        this.start_date.substring(8, 10)
      ).getTime();
      this.timeStart = new Date(startDate).toLocaleDateString();
      const endDate = new Date(
        this.end_date.substring(0, 4),
        parseInt(this.end_date.substring(5, 7), 10) - 1,
        this.end_date.substring(8, 10),
        23,
        59,
        59
      ).getTime();
      this.timeEnd = new Date(endDate).toLocaleDateString();
      let tempTable = [...this.tableData];
      for (let t = tempTable.length - 1; t > -1; t--) {
        if (tempTable[t].date !== 'unspecified') {
          if (tempTable[t].date < startDate || tempTable[t].date > endDate) {
            tempTable.splice(t, 1);
          }
        }
      }
      if (this.tab === 'cabinet') {
        tempTable = tempTable.filter(
          (row) => this.queryCabinets.indexOf(row.cabinet) > -1
        );
      }
      if (this.tab === 'commissioner') {
        tempTable = tempTable.filter(
          (row) => this.queryCommissioners.indexOf(row.commissioner) > -1
        );
      }
      const splitTables = [];
      if (
        this.tab === 'cabinet' ||
        (this.tab === 'dates' &&
          (this.sortBy === 'cabinet' || this.sortBy === 'מרכזיה'))
      ) {
        let cabinets = [];
        for (const j of tempTable) {
          cabinets.push(j.cabinet);
        }
        cabinets = [...new Set(cabinets)];
        for (const z of cabinets) {
          splitTables.push(tempTable.filter((row) => row.cabinet === z));
        }
      } else {
        let commissioner = [];
        for (const j of tempTable) {
          commissioner.push(j.commissioner);
        }
        commissioner = [...new Set(commissioner)];
        for (const z of commissioner) {
          splitTables.push(tempTable.filter((row) => row.commissioner === z));
        }
      }
      const mapTemp = {};
      const filteredDevicesMap = [];
      for (let a = 0; a < splitTables.length; a++) {
        mapTemp[a] = false;
        filteredDevicesMap.push([]);

        for (let b = 0; b < splitTables[a].length; b++) {
          splitTables[a][b].index = b;
          splitTables[a][b].cabinetSelected = false;
          splitTables[a][b].commissionerSelected = false;
          splitTables[a][b].connectedSelected = false;
          splitTables[a][b].disconnectedSelected = false;
          splitTables[a][b].installedSelected = false;
        }
      }
      this.mapOpen = mapTemp;
      this.filterDevicesList = filteredDevicesMap;
      this.cabinetPerTableIndex = filteredDevicesMap.flat(1);
      this.viewedTableData = [];
    
      splitTables.forEach((cabinet_data, index) => {
        this.viewedTableData[index] = {cabinet_id: splitTables[index][0].cabinet, data: cabinet_data};
      });
      const exportedTable = JSON.parse(JSON.stringify(tempTable));
      const disconnectedDevices = [];
      const allDevices = [];
      for (const r of exportedTable) {
        for (const t of r.disconnected) {
          const metaDevice = t['meta.device'];
          const device = {
            'cabinet': metaDevice.cabinet_id || 'N/A',
            'pole number': metaDevice.pole_number || 'N/A',
            'pole height': metaDevice.pole_height || 'N/A',
            'circuit number': metaDevice.circuit_number || 'N/A',
            'device_id': t.id,
            'location': t['meta.location'].lat + ', ' + t['meta.location'].lng,
            'installer': r.commissioner,
            'date':
              typeof t.date === 'number'
                ? new Date(r.date).toLocaleDateString()
                : 'N/A'
          };
          disconnectedDevices.push(device);
        }
        for (const t of r.installed) {
          const metaDevice = t['meta.device'];
          const device = {
            'cabinet': metaDevice.cabinet_id || 'N/A',
            'pole number': metaDevice.pole_number || 'N/A',
            'pole height': metaDevice.pole_height || 'N/A',
            'circuit number': metaDevice.circuit_number || 'N/A',
            'device_id': t.id,
            'location': t['meta.location'].lat + ', ' + t['meta.location'].lng,
            'installer': r.commissioner,
            'status': t['sys___active'] === -3 ? 'disconnected' : 'connected',
            'date':
              typeof t.date === 'number'
                ? new Date(r.date).toLocaleDateString()
                : 'N/A'
          };
          allDevices.push(device);
        }
      }
      this.exportAllData = allDevices;
      this.exportData = disconnectedDevices;
      this.reportOpen = true;
      this.datesChange = false;
      this.commissionersChange = false;
      this.cabinetsChange = false;
    },
    closeReport() {
      // if (this.tab !== 'dates') {
      //   this.setDefaultDates();
      // }
      this.reportOpen = false;
    },
    selectCabinet(cabinet) {
      if (this.queryCabinets.indexOf(cabinet) > -1) {
        this.queryCabinets.splice(this.queryCabinets.indexOf(cabinet), 1);
      } else {
        this.queryCabinets.push(cabinet);
      }
    },
    selectCommissioner(commissioner) {
      if (this.queryCommissioners.indexOf(commissioner) > -1) {
        this.queryCommissioners.splice(
          this.queryCommissioners.indexOf(commissioner),
          1
        );
      } else {
        this.queryCommissioners.push(commissioner);
      }
    },
    setStartDate(start) {
      const projectDates = this.$store.state.Projects.projects_metadata.get(this.$store.state.User.project.id).commissionedByDate;
      
      if (!start || start === this.end_date) {
        if (this.tab !== 'dates') {
          this.projectStartTime = projectDates[0];
          this.projectEndTime = new Date().getTime();
          const date = new Date(projectDates[0]).toISOString().substring(0, 10);
          this.start_date = date;
        } else {
          this.projectStartTime = this.projectEndTime - 7 * 86400000;
          const date = new Date(this.projectStartTime)
            .toISOString()
            .substring(0, 10);
          this.start_date = date;
        }
      } else {
        this.start_date = start;
      }
    },
    setYesterday() {
      this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      const now = new Date(this.end_date.split('-')).getTime();
      const newTime = now - 86400000;
      this.setStartDate(
        String(new Date(newTime).getFullYear()) +
          '-' +
          String(new Date(newTime).getMonth() + 1).padStart(2, 0) +
          '-' +
          String(new Date(newTime).getDate()).padStart(2, 0)
      );
    },
    setLastWeek() {
      this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      const now = new Date(this.end_date.split('-')).getTime();
      const newTime = now - 86400000 * 6;
      this.setStartDate(
        String(new Date(newTime).getFullYear()) +
          '-' +
          String(new Date(newTime).getMonth() + 1).padStart(2, 0) +
          '-' +
          String(new Date(newTime).getDate()).padStart(2, 0)
      );
    },
    setLastMonth() {
      this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      const now = new Date(this.end_date.split('-')).getTime();
      const newTime = now - 86400000 * 30;
      this.setStartDate(
        String(new Date(newTime).getFullYear()) +
          '-' +
          String(new Date(newTime).getMonth() + 1).padStart(2, 0) +
          '-' +
          String(new Date(now).getDate()).padStart(2, 0)
      );
    },
    setLastYear() {
      this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      const now = new Date(this.end_date.split('-')).getTime();
      const newTime = now - 86400000 * 365;
      this.setStartDate(
        String(new Date(newTime).getFullYear()) +
          '-' +
          String(new Date(newTime).getMonth() + 1).padStart(2, 0) +
          '-' +
          String(new Date(newTime).getDate()).padStart(2, 0)
      );
    },
    setEndDate(end) {
      this.end_date = end;
    },
    resetChange(x) {
      this.changeIndex = x;
    },
    setDefaultDates() {
      this.set_default = true;
      this.setStartDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      this.set_default = false;
    },
    getDevices() {
      const tempDevices = this.devices.filter((device) => Utils.hasTondoClass(device.class_name));
      for (const i of tempDevices) {
        try {
          if (!i['meta.device'].cabinet_id) {
            i['meta.device'].cabinet_id = 'unspecified';
          }
          if (!i['meta.commission'].hasOwnProperty('commissioner')) {
            i['meta.commission'].commissioner = 'unspecified';
          }
          i.date = this.getDeviceTime(i);
        } catch (e) {
          console.log(i);
        }
      }
      const devices = tempDevices.filter((device) => device['meta.commission'].commissioned === true);
      this.allDevices = devices;
      const commissionersList = [];
      for (const k of devices) {
        if (k['meta.commission'].hasOwnProperty('commissioner') && k['meta.commission']['commissioner'] && k['meta.commission']['commissioner'].length > 0) {
          commissionersList.push(
            k['meta.commission'].commissioner === 'none'
              ? 'unspecified'
              : k['meta.commission'].commissioner.split('@')[0].trim()
          );
        } else {}
      }
      this.commissionersList = [...new Set(commissionersList)];
      this.queryCommissioners = this.commissionersList;
      const datesortedDevices = devices
        .filter((dev) => dev.date !== 'unspecified')
        .sort((a, b) =>
          a.date > b.date &&
          a.date !== 'unspecified' &&
          b.date !== 'unspecified'
            ? 1
            : a.date === 'unspecified'
            ? 1
            : -1
        );
      const dateSplitObject = {};
      for (const device of datesortedDevices) {
        if (dateSplitObject.hasOwnProperty(device.date)) {
          dateSplitObject[`${device.date}`].devices.push(device);
        } else {
          dateSplitObject[`${device.date}`] = {};
          dateSplitObject[`${device.date}`].devices = [device];
        }
      }
      for (const date in dateSplitObject) {
        for (const a of dateSplitObject[date].devices) {
          try {
            const cabinetID = a['meta.device'].cabinet_id;
            const commissioner = a['meta.commission'].commissioner.split('@')[0].trim();
            const device = a;
            if (!dateSplitObject[date].hasOwnProperty(cabinetID)) {
              dateSplitObject[date][`${cabinetID}`] = {};
            }
            if (!dateSplitObject[date][`${cabinetID}`].hasOwnProperty(`${commissioner}`)) {
              dateSplitObject[date][`${cabinetID}`][`${commissioner}`] = [device];
            } else {
              dateSplitObject[date][`${cabinetID}`][`${commissioner}`].push(device);
            }
          } catch (e) {
            console.log(e);
          }
        }
        delete dateSplitObject[date].devices;
      }
      const tableData = [];
      for (const date in dateSplitObject) {
        if (date !== 'undefined') {
          for (const cabinet in dateSplitObject[date]) {
            for (const commissioner in dateSplitObject[date][cabinet]) {
              const data = dateSplitObject[date][cabinet][commissioner];
              const disconnected = dateSplitObject[date][cabinet][
                commissioner
              ].filter((dev) => dev.sys___active === -3);
              const connected = dateSplitObject[date][cabinet][
                commissioner
              ].filter((dev) => dev.sys___active !== -3);
              tableData.push({
                date: parseInt(date, 10),
                cabinet,
                cabinetSelected: false,
                commissioner,
                commissionerSelected: false,
                installed: data,
                installedSelected: false,
                connected,
                connectedSelected: false,
                disconnected,
                disconnectedSelected: false,
                index: null
              });
            }
          }
        }
      }
      if (dateSplitObject.hasOwnProperty('undefined')) {
        delete dateSplitObject['undefined'];
      }
      for (let m = 0; m < tableData.length; m++) {
        tableData[m].index = m;
      }
      this.tableData = tableData;
    },
    getDeviceTime(device) {
      let month;
      let year;
      let time;
      if (
        device['meta.commission'].date.indexOf('.') !== -1 &&
        device['meta.commission'].date !== 'none'
      ) {
        if (device['meta.commission'].date.substring(3, 5) === '01') {
          month = 0;
          year = parseInt(device['meta.commission'].date.substring(6, 10), 10);
        } else {
          month =
            parseInt(device['meta.commission'].date.substring(3, 5), 10) - 1;
        }
        time = new Date(
          year
            ? year.toString()
            : device['meta.commission'].date.substring(6, 10),
          month,
          device['meta.commission'].date.substring(0, 2)
        ).getTime();
        if (isNaN(time)) {
          if (device['meta.commission'].date.substring(5, 7) === '01') {
            month = 0;
            year = parseInt(device['meta.commission'].date.substring(0, 4), 10);
          } else {
            month =
              parseInt(device['meta.commission'].date.substring(5, 7), 10) - 1;
          }
          return new Date(
            year
              ? year.toString()
              : device['meta.commission'].date.substring(0, 4),
            month,
            device['meta.commission'].date.substring(8, 10)
          ).getTime();
        } else return time;
      } else if (device['meta.commission'].date !== 'none') {
        if (device['meta.commission'].date.substring(0, 2) === '01') {
          month = 0;
          year = parseInt(device['meta.commission'].date.substring(6, 10), 10);
        } else {
          month =
            parseInt(device['meta.commission'].date.substring(0, 2), 10) - 1;
        }
        return new Date(
          year
            ? year.toString()
            : device['meta.commission'].date.substring(6, 10),
          month,
          device['meta.commission'].date.substring(3, 5)
        ).getTime();
      }
    },
    setTableActiveDevices(index, table) {
      let deviceArr = [];
      const tempViewed = JSON.parse(JSON.stringify(table));
      for (const m of tempViewed) {
        if (m.commissionerSelected || m.installedSelected) {
          deviceArr = [...deviceArr, ...m.installed.map((device) => device.id)];
        } else if (m.connectedSelected) {
          deviceArr = [...deviceArr, ...m.connected.map((device) => device.id)];
        } else if (m.disconnectedSelected) {
          deviceArr = [...deviceArr, ...m.disconnected.map((device) => device.id)];
        }
        if (m.cabinetSelected && this.cabinetsFullData.find((cab) => cab['meta.device'].cabinet_id === m.cabinet)) {
          deviceArr = [
            ...deviceArr,
            this.cabinetsFullData.find((cab) => cab['meta.device'].cabinet_id === m.cabinet).id,
            ...m.installed.map((device) => device.id)
          ];
        }
      }
      deviceArr = [...new Set(deviceArr)];
      if (deviceArr.length === 0) {
        Vue.set(this.mapOpen, index, false);
      } else {
        Vue.set(this.mapOpen, index, true);
      }
      Vue.set(this.filterDevicesList, index, deviceArr);
      this.viewedTableData[index].data = tempViewed;
      this.changeIndex = index;
    },
    selectMapCommissioner(index, commissioner) {
      const tempViewed = JSON.parse(
        JSON.stringify(this.viewedTableData[index].data)
      );
      for (const i of tempViewed) {
        if (i.commissioner === commissioner) {
          i.commissionerSelected = !i.commissionerSelected;
        }
      }
      this.setTableActiveDevices(index, tempViewed);
    },
    selectMapCabinet(index, cabinet, table) {
      const tempViewed = JSON.parse(JSON.stringify(table));
      for (const i of tempViewed) {
        if (i.cabinet === cabinet) {
          i.cabinetSelected = !i.cabinetSelected;
        }
      }
      this.setTableActiveDevices(index, tempViewed);
    },
    selectMapDevices(index, row, type) {
      const tempViewed = JSON.parse(
        JSON.stringify(this.viewedTableData[index].data)
      );
      if (type === 'installed') {
        tempViewed[row].installedSelected = !tempViewed[row].installedSelected;
      } else if (type === 'connected') {
        tempViewed[row].connectedSelected = !tempViewed[row].connectedSelected;
      } else {
        tempViewed[row].disconnectedSelected =
          !tempViewed[row].disconnectedSelected;
      }
      this.setTableActiveDevices(index, tempViewed);
    },
    selectCol(index, col, open = null) {
      const tempViewed = JSON.parse(
        JSON.stringify(this.viewedTableData[index].data)
      );
      let boolCheck = false;
      if (col === 'Installed Devices') {
        if (open === null) {
          for (const i of tempViewed) {
            if (!i.installedSelected) {
              boolCheck = true;
              break;
            }
          }
          for (const j of tempViewed) {
            j.installedSelected = boolCheck;
          }
        } else if (open) {
          for (const j of tempViewed) {
            j.installedSelected = true;
          }
        } else {
          for (const j of tempViewed) {
            j.installedSelected = false;
          }
        }
      }
      if (col === 'Installation Success') {
        for (const i of tempViewed) {
          if (!i.connectedSelected) {
            boolCheck = true;
            break;
          }
        }
        for (const j of tempViewed) {
          j.connectedSelected = boolCheck;
        }
      }
      if (col === 'Installation Fail') {
        for (const i of tempViewed) {
          if (!i.disconnectedSelected) {
            boolCheck = true;
            break;
          }
        }
        for (const j of tempViewed) {
          j.disconnectedSelected = boolCheck;
        }
      }
      if (col === 'Installer') {
        for (const i of tempViewed) {
          if (!i.commissionerSelected) {
            boolCheck = true;
            break;
          }
        }
        for (const j of tempViewed) {
          j.commissionerSelected = boolCheck;
        }
      }
      this.setTableActiveDevices(index, tempViewed);
    },
    getChangedTableData(index) {
      return this.tableData[index];
    },
    print() {
      this.filterDevicesList.fill([]);
      for (const key in this.mapOpen) {
        Vue.set(this.mapOpen, key, false);
      }
      for (const table of this.viewedTableData) {
        for (const row of table.data) {
          row.cabinetSelected = false;
          row.commissionerSelected = false;
          row.connectedSelected = false;
          row.disconnectedSelected = false;
          row.installedSelected = false;
        }
      }
      setTimeout(() => {
        window.print();
      }, 500);
    }
  },
  computed: {
    language() {
      return this.$store.state.Global.lang;
    },
    dataPeriod() {
      return this.period;
    },
    mapIndexChange() {
      return this.mapOpen;
    },
    tabChange() {
      return this.tab;
    },
    devicesCount() {
      return this.$store.state.Reports.devicesCount;
    },
    devices() {
      return this.$store.state.Reports.reportsList;
    }
  },
  watch: {
    language(newVal) {
      if (newVal === 'he') {
        this.period = this.$t(this.period);
        this.sortBy = this.$t(this.sortBy);
      } else {
        this.period = this.periodList[this.periodListHe.indexOf(this.period)];
        this.sortBy = this.sortList[this.sortListHe.indexOf(this.sortBy)];
      }
      this.lang = newVal;
    },
    dataPeriod(newVal) {
      this.period = newVal;
      this.setStartDate();
    },
    devices() {
      this.generatePageData();
    },
    devicesCount() {
      this.generatePageData();
    },
    mapIndexChange(newVal) {
      this.mapOpen = newVal;
    },
    tabChange(newVal) {
      if (newVal === 'dates') {
        this.period = 'week';
      } else if (newVal === 'commissioner' || newVal === 'cabinet') {
        this.period = 'all';
      }
    }
  }
};
</script>

<style scoped>
.tabs {
  width: 100%;
  max-height: 45px;
  background-color: #f2f4f8;
}
.tabs button {
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Open Sans', 'Arial', monospace !important;
  padding: 5px;
  font-weight: 400;
}

.tabs .button-active {
  border: 0.5px solid #d8e3f3;
  border-radius: 10px;
  color: #7e57c2;
  font-weight: 600;
  background-color: #f2f4f8;
}

.closeIcon {
  color: black;
  cursor: pointer;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 90%;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  height: 90%;
}
</style>

<style>
.monitoring-report-data div {
  padding: 1px !important;
}
.selectionBtn {
  background-color: #7e9edf;
  padding: 4px 8px;
  border-radius: 5px;
}
.selectErr {
  color: red;
}
.noDataAvail {
  font-weight: bold;
  font-size: 24px;
}
.monitoringReportsHeader {
  margin: 0px 30px 0px 30px;
}
.monitoringReportsHeader {
  font-size: 30px;
  font-weight: bold;
  color: black;
}
.monitoringReportsback {
  margin: 30px 20px 0px 20px;
}
.selectedType {
  font-size: 20px;
  color: #065fd4;
  font-weight: bold;
}
.unselectedType {
  font-size: 20px;
  color: black;
  font-weight: bold;
  cursor: pointer;
}
.monitoringReportsCardHeader {
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.selectedValue {
  font-size: 16px;
  color: #065fd4;
  cursor: pointer;
  font-weight: bold;
}
.unselectedValue {
  font-size: 16px;
  color: black;
  cursor: pointer;
  font-weight: bold;
}
.selectSecondaryField {
  font-size: 18px;
  font-weight: bold;
  color: black;
}
.tableHeader {
  font-size: 18px;
  font-weight: bold;
}
.text-lg-center {
  font-size: 16px;
}
.redText {
  color: red !important;
}
.font-16-bold {
  font-weight: bold;
  font-size: 16px;
}
.font-16-bold-clickable {
  color: #065fd4;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.cardHeaderMonitoring {
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.selectHeaderMonitoring {
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat):not(
    .v-btn--outline
  ) {
  background-color: grey !important;
  color: black !important;
  border-color: rgb(129, 46, 129);
}

.errorTextBold {
  font-size: 22px;
  font-weight: bold;
}
.userInputs {
  width: 250px;
  height: 30px;
  border: 1px solid grey;
  margin-left: 10px;
  text-align: left;
  padding-left: 5px;
  cursor: pointer;
}
.custom__tag {
  font-size: 18px;
  font-weight: bold;
}
.multiselect__element {
  padding-top: 5px;
}
.multiselect__content-wrapper {
  padding-top: 6px;
}
.multiselect__input {
  font-size: 20px;
}
.multiselect__element {
  padding-top: 5px;
}
.multiselect__content-wrapper {
  padding-top: 6px;
}
fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: a 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: a 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 480px;
  min-height: 40px;
  text-align: left;
  color: #35495e;
  padding: 20px 10px;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  opacity: 0.6;
}
.multiselect--active {
  z-index: 1;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}
.multiselect__input::-webkit-input-placeholder {
  color: #35495e;
}
.multiselect__input:-ms-input-placeholder {
  color: #35495e;
}
.multiselect__input::placeholder {
  color: #35495e;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  font-weight: bold;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: '\D7';
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 25px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #999 transparent transparent;
  content: '';
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}
.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: #fff;
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  cursor: text;
  pointer-events: none;
}
.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}
.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}
.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
[dir='rtl'] .multiselect {
  text-align: right;
}
[dir='rtl'] .multiselect__select {
  right: auto;
  left: 1px;
}
[dir='rtl'] .multiselect__tags {
  padding: 8px 8px 0 40px;
}
[dir='rtl'] .multiselect__content {
  text-align: right;
}
[dir='rtl'] .multiselect__option:after {
  right: auto;
  left: 0;
}
[dir='rtl'] .multiselect__clear {
  right: auto;
  left: 12px;
}
[dir='rtl'] .multiselect__spinner {
  right: auto;
  left: 1px;
}
@keyframes a {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
@media print {
  @page {
    size: landscape;
    margin: 0;
  }
  * {
    overflow: visible !important;
  }
  .v-navigation-drawer,
  .main-toolbar,
  .monitoringReportsback,
  .btnsHide {
    visibility: hidden;
    display: none;
  }
  .ml-4,
  .mr-4,
  .mt-3 {
    margin: 0;
  }
  .piecharten {
    width: 200px;
    margin-right: 100px;
  }
  .piecharthe {
    width: 200px;
    margin-left: 60px;
  }
  .printElement {
    visibility: visible;
    position: absolute;
    right: 0;
    top: 0;
    width: fit-content;
  }
  .v-card {
    border: none !important;
  }
  .tableHeader {
    margin-top: 20px !important;
    margin-bottom: 30px !important;
  }
}
.v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
  box-shadow: none !important;
}
</style>
