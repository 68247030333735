<template>
  <v-data-table v-if="!change"
    :pagination.sync="pagination"
    :headers="headersTable"
    :items="itemsTable"
    :rows-per-page-text="$t('Rows per page')"
    :no-data-text="$t('No data available')"
  >
    <template slot="headerCell" slot-scope="props">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on" class="center mt-1 tableHeader" @click="selectCol(indexTable, props.header.text)" 
          :style="props.header.text === 'Installed Devices'||props.header.text === 'Installation Success'||props.header.text === 'Installation Fail'?'cursor:pointer;white-space: break-spaces;':props.header.text === 'Installer'?'cursor:pointer;':''">
            {{$t(props.header.text)}}
          </div>
        </template>
        <span class="tableHeader">
          {{ $t(props.header.text) }}
        </span>
      </v-tooltip>
    </template>
    <template v-slot:pageText="props">
      {{ props.pageStart }} - {{ props.pageStop }} {{ $t('of') }}
      {{ props.itemsLength }}
    </template>
    <template v-slot:items="props">
      <tr>
        <td
          :class="
            props.item.date !== 'unspecified' &&
            realCabinets.indexOf(props.item.cabinet) !== -1
              ? 'text-lg-center text-sm-center '
              : 'text-lg-center text-sm-center redText'
          "
        >
          {{ $t(getDate(props.item.date)) }}
        </td>
        <td
          :class="
            commissionersList.indexOf(props.item.commissioner) > -1 &&
            realCabinets.indexOf(props.item.cabinet) !== -1 &&
            props.item.commissioner !== 'unspecified' 
              ? 'text-lg-center text-sm-center cursor'
              : 'text-lg-center text-sm-center redText cursor'
          "
          :style="
            !props.item.commissionerSelected
              ? ''
              : 'background:linear-gradient(#42489a, #814283, #9b416e); color: white'
          "
          @click="selectMapCommissioner(indexTable, props.item.commissioner)"
        >
          {{ props.item.commissioner }}
        </td>
        <td
          :class="
            realCabinets.indexOf(props.item.cabinet) !== -1 
              ? 'text-lg-center text-sm-center cursor'
              : 'text-lg-center text-sm-center redText cursor'
          "
          :style="
            !props.item.cabinetSelected
              ? ''
              : 'background:linear-gradient(#42489a, #814283, #9b416e); color: white'
          "
          @click="selectMapCabinet(indexTable, props.item.cabinet, tableData)"
        >
          {{ props.item.cabinet }}
        </td>
        <td
          :class="
            realCabinets.indexOf(props.item.cabinet) !== -1 
              ? 'text-lg-center text-sm-center cursor'
              : 'text-lg-center text-sm-center redText cursor'
          "
          :style="
            !props.item.installedSelected
              ? ''
              : 'background:linear-gradient(#42489a, #814283, #9b416e); color: white'
          "
          @click="selectMapDevices(indexTable, props.item.index, 'installed')"
        >
          {{ props.item.installed.length }}
        </td>
        <td
          :class="
            realCabinets.indexOf(props.item.cabinet) !== -1 
              ? 'text-lg-center text-sm-center cursor'
              : 'text-lg-center text-sm-center redText cursor'
          "
          :style="
            !props.item.connectedSelected
              ? ''
              : 'background:linear-gradient(#42489a, #814283, #9b416e); color: white'
          "
          @click="selectMapDevices(indexTable, props.item.index, 'connected')"
        >
          {{ props.item.connected.length }}
        </td>
        <td
          :class="
            realCabinets.indexOf(props.item.cabinet) !== -1 
              ? 'text-lg-center text-sm-center cursor'
              : 'text-lg-center text-sm-center redText cursor'
          "
          :style="
            !props.item.disconnectedSelected
              ? ''
              : 'background:linear-gradient(#42489a, #814283, #9b416e); color: white'
          "
          @click="selectMapDevices(indexTable, props.item.index, 'disconnected')"
        >
          {{ props.item.disconnected.length }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'MonitoringReportTable',
  props: [
    'headers',
    'tableData',
    'index',
    'comList',
    'cabList',
    'changeIndex',
    'realCabinetsList',
    'cabinetsFullData',
    'resetChange', 
    'selectMapCommissioner',
    'selectMapCabinet',
    'selectMapDevices',
    'selectCol'
  ],
  data() {
    return {
      pagination: null,
      headersTable: null,
      itemsTable: null,
      indexTable: null,
      commissionersList: null,
      cabinetsList: null,
      change: false,
      realCabinets: null
    };
  },
  beforeMount() {
    this.pagination = {
      descending: true
    };
    this.realCabinets = this.realCabinetsList;
    this.commissionersList = this.comList;
    this.cabinetsList = this.cabList;
    this.headersTable = this.headers;
    this.indexTable = this.index;
    this.itemsTable = this.tableData;
  },
  methods: {
    getDate(date) {
      if (date !== 'unspecified') {
        const tempDate = new Date(date);
        const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
        return tempDate.toLocaleDateString('en-GB', options);
      } else {
        return date;
      }
    }
  },
  computed: {
    dataChange() {
      return this.changeIndex;
    },
    tableChange(){
      return this.tableData;
    }
  },
  watch: {
    dataChange(newVal) {
      if (newVal === this.indexTable) {
        this.change = true;
        this.resetChange(10000);
        this.change = false;
      }
    },
    tableChange(newValue){
      this.itemsTable = newValue;
    }
  }
};
</script>
