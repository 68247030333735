<template>
    <v-card class="mb-2 blue-rounded-card" style="background-color: white !important">
        <v-layout
            row
            wrap
            v-if="filterDevicesList[index].length > 0 && mapOpen[index]"
        >
            <v-flex xs6>
                <v-layout row wrap>
                    <v-flex xs6>
                        <v-card class="ml-3 mr-3">
                            <MonitoringReportPie
                                :lang="lang"
                                :data="viewedTableData[index].data"
                                :index="index"
                            />
                        </v-card>
                    </v-flex>
                    <v-flex xs6>
                        <v-layout row wrap>
                            <v-flex xs12> </v-flex>
                        </v-layout>
                        <v-layout justify-center class="tableHeaderMonitoring">
                            {{
                            tab === 'cabinet' ||
                            (tab === 'dates' &&
                                (sortBy === 'cabinet' || sortBy === 'מרכזיה'))
                                ? $t('Cabinet') +
                                ' ' +
                                viewedTableData[index].cabinet_id +
                                ' ' +
                                $t('report for dates')
                                : $t('Installer') +
                                ' ' +
                                viewedTableData[index].data[0].commissioner +
                                ' ' +
                                $t('report for dates')
                            }}
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex xs12>
                                <v-layout justify-center class="tableHeaderMonitoring mt-0">
                                    {{ timeStart + '-' + timeEnd }}
                                </v-layout>
                            </v-flex>
                            <v-flex
                                sm12
                                v-if="
                                    realCabinetsList.indexOf(
                                    viewedTableData[index].cabinet_id
                                    ) > -1 &&
                                    (tab === 'cabinet' ||
                                    (tab === 'dates' &&
                                        (sortBy === 'cabinet' || sortBy === 'מרכזיה')))
                                "
                            >
                                <v-layout justify-center class="mt-3">
                                    <button
                                    class="selectionBtn"
                                    @click="openCabinetPage(viewedTableData[index].cabinet_id)"
                                    >
                                    {{ $t('Open Cabinet Page') }}
                                    </button>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12>
                        <MonitoringReportTable
                            :headers="headers"
                            :tableData="viewedTableData[index].data"
                            :index="index"
                            :comList="commissionersList"
                            :cabList="cabinetsList"
                            :changeIndex="changeIndex"
                            :realCabinetsList="realCabinetsList"
                            :cabinetsFullData="cabinetsFullData"
                            :resetChange="resetChange"
                            :selectMapCommissioner="selectMapCommissioner"
                            :selectMapCabinet="selectMapCabinet"
                            :selectMapDevices="selectMapDevices"
                            :selectCol="selectCol"
                        />
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs6 style="width: 100%">
                <MapView
                    :filterDevicesList="filterDevicesList[index]"
                    :displayOnly="true"
                />
            </v-flex>
        </v-layout>
        <v-layout row wrap v-else>
            <v-flex xs12 class="tableHeader">
                <v-layout row wrap>
                    <v-flex xs12> </v-flex>
                </v-layout>
                <v-layout justify-center class="tableHeaderMonitoring">
                    {{
                    tab === 'cabinet' ||
                    (tab === 'dates' &&
                        (sortBy === 'cabinet' || sortBy === 'מרכזיה'))
                        ? $t('Cabinet') +
                        ' ' +
                        viewedTableData[index].cabinet_id +
                        ' ' +
                        $t('report for dates')
                        : $t('Installer') +
                        ' ' +
                        viewedTableData[index].data[0].commissioner +
                        ' ' +
                        $t('report for dates')
                    }}
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-layout justify-center class="tableHeaderMonitoring mt-0">
                            {{ timeStart + '-' + timeEnd }}
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex sm3>
                <MonitoringReportPie
                    :data="viewedTableData[index].data"
                    :index="index"
                />
            </v-flex>
            <v-flex sm9>
                <MonitoringReportTable
                    :class="lang === 'he' ? 'reportTableHe' : 'reportTable'"
                    :headers="headers"
                    :tableData="viewedTableData[index].data"
                    :index="index"
                    :comList="commissionersList"
                    :cabList="cabinetsList"
                    :changeIndex="changeIndex"
                    :realCabinetsList="realCabinetsList"
                    :cabinetsFullData="cabinetsFullData"
                    :resetChange="resetChange"
                    :selectMapCommissioner="selectMapCommissioner"
                    :selectMapCabinet="selectMapCabinet"
                    :selectMapDevices="selectMapDevices"
                    :selectCol="selectCol"
                />
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
    import MonitoringReportPie from '@/pages/reports/components/MonitoringReportPie.vue';
    import MonitoringReportTable from '@/pages/reports/components/MonitoringReportTable.vue';
    import MapView from '@/pages/mapView/MapView.vue';

    export default {
        name: 'item-component',
        components: {
            MonitoringReportPie,
            MonitoringReportTable,
            MapView
        },
        props: {
            index: { // index of current item
                type: Number
            },
            source: {
                type: Object,
                default() {
                    return {};
                }
            },
            filterDevicesList: {
                type: Array
            },
            mapOpen: {
                type: Object
            },
            viewedTableData: {
                type: Array
            },
            tab: {
                type: String
            },
            sortBy: {
                type: String
            },
            timeStart: {
                type: String
            },
            timeEnd: {
                type: String
            },
            realCabinetsList: {
                type: Array
            },
            openCabinetPage: {
                type: Function
            },
            headers: {
                type: Array
            },
            commissionersList: {
                type: Array
            },
            cabinetsList: {
                type: Array
            },
            changeIndex: {
                type: Number
            },
            cabinetsFullData: {
                type: Array
            },
            resetChange: {
                type: Function
            }, 
            selectMapCommissioner: {
                type: Function
            },
            selectMapCabinet: {
                type: Function
            },
            selectMapDevices: {
                type: Function
            },
            selectCol: {
                type: Function
            }
        },
        computed: {
            lang(){
                return this.$store.state.Global.lang;
            }
        }
    };
</script>
<style scoped>
  .reportTable {
    width: -webkit-fill-available;
    margin-right: 15px !important;
    overflow-y: hidden !important;
    break-after: always !important;
  }
  .reportTableHe {
    width: -webkit-fill-available;
    margin-left: 15px;
    overflow-y: hidden !important;
    break-after: always !important;
  }
  .tableHeaderMonitoring {
    font-size: 24px;
    font-weight: bold;
    color: black;
  }
  .selectHeaderMonitoring {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
  .selectionBtn {
    background-color: #7e9edf;
    padding: 4px 8px;
    border-radius: 5px;
  }
  .tableHeader {
    font-size: 18px;
    font-weight: bold;
  }
</style>